
  .c-button {
    color: #000;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    padding: 0.9em 1.6em;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 1;
  }
  
  .c-button--gooey {
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 4px solid #000000;
    border-radius: 20px;
    position: relative;
    transition: all 700ms ease;
  }
  /* ... (existing styles) */

/* ... (existing styles) */

.c-button--gooey.transparent-button {
    background-color: transparent;
    color: white;
    border-color: white;
  }
  .c-button--gooey.transparent-button:hover{
    color: black;
  }
  .c-button--gooey.transparent-button .white-blobs div {
    background-color: white;
  }
  
  /* ... (existing styles) */
  
  /* ... (existing styles) */
  
  .c-button--gooey .c-button__blobs {
    height: 100%;
    filter: url(#goo);
    overflow: hidden;
    position: absolute;
    top: 0;
    border-radius: 16px;
    left: 0;
    bottom: -3px;
    right: -1px;
    z-index: -1;
  }
  
  .c-button--gooey .c-button__blobs div {
    background-color: #000000;
    width: 34%;
    height: 100%;
    border-radius: 100%;
    position: absolute;
    transform: scale(1.4) translateY(125%) translateZ(0);
    transition: all 700ms ease;
  }
  
  .c-button--gooey .c-button__blobs div:nth-child(1) {
    left: -5%;
  }
  
  .c-button--gooey .c-button__blobs div:nth-child(2) {
    left: 30%;
    transition-delay: 60ms;
  }
  
  .c-button--gooey .c-button__blobs div:nth-child(3) {
    left: 66%;
    transition-delay: 25ms;
  }
  
  .c-button--gooey:hover {
    color: #fff;
  }
  
  .c-button--gooey:hover .c-button__blobs div {
    transform: scale(1.4) translateY(0) translateZ(0);
  }