.test::after{
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
  .pr-gr{
    transform-origin: 0px 0px 0px;
    transition: transform linear;
  }
  .pr-in-gr{
    position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border: calc(.0624rem/var(--item-scale, 1)) solid rgba(235 , 235 , 235 , 1);
  background: white;
  border-radius: .5rem;
  }
  .test{
  z-index: 5;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: .5rem;
  transform: translateZ(0);
  -webkit-mask-image: radial-gradient(white,black);
  mask-image: radial-gradient(#fff,#000);
  
  }
  .ovl-pr-gr{
    position: absolute;
    z-index: 11;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: #00000080;
    border-radius: .5rem;
    transition: opacity var(--p-motion-duration-150);
    cursor: pointer;
    color: rgb(48 , 48 , 48);
  }
  .ovl-pr-div{
    margin-left: .5rem;
    margin-bottom: .5rem;
  }
  .ovl-pr-spn{
    position: absolute;
    top: calc(.37rem + .062rem);
    right: calc(.5rem - .1875rem);
    border: none;
    border-radius: var(--p-border-radius-150);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    cursor: grab;
  }
  .ovl-pr-spn-sc{
    display: block;
    height: 1.25rem;
    width: 1.25rem;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
  
  .ovl-pr-svg{
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  .ovl-pr-gr:hover{
    opacity: 1;
  }
  .ovl-pr-btn{
    color: rgb(142 , 31 ,11);
    padding-block: .37rem;
    padding-inline: .75rem;
    box-shadow: 0rem -.0625rem 0rem 0rem #b5b5b5 inset, 0rem 0rem 0rem .0625rem rgba(0, 0, 0, .1) inset, 0rem .03125rem 0rem .09375rem #FFF inset;
    background: white;
    border-radius: .5rem;
  }
  .ovl-pr-btn:hover{
    color: rgb(95 , 21 ,7);
  
  }
  .ovl-pr-btn:active{
    box-shadow: -.0625rem 0rem .0625rem 0rem rgba(26, 26, 26, .122) inset, .0625rem 0rem .0625rem 0rem rgba(26, 26, 26, .122) inset, 0rem .125rem .0625rem 0rem rgba(26, 26, 26, .2) inset;
  }
  .pr-up-gr{
    height: auto;
    border: .062rem dashed rgb(204 , 204 , 204);
    border-radius: .5rem;
    background-color: white;
    padding: .5rem;
    aspect-ratio: 1;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .25rem;
  }
  .pr-up-div{
    font-size: .75rem;
    font-weight: 650;
    display: grid;
    flex-wrap: wrap;
    gap: .75rem;
    justify-content: center;
    align-items: center;
  }
  
  .-btn-op-ad{
    position: relative;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    gap: .125rem;
    padding: .37rem .75rem;
    background: var(--btn-bg);
    border: none;
    border-radius: .5rem;
    color: white;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    box-shadow: 0rem -.0625rem 0rem .0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem .0625rem rgb(48, 48, 48) inset, 0rem .03125rem 0rem .09375rem rgba(255, 255, 255, .25) inset;
    --btn-bg-gr: var(--btn-bg-color-fill);
    --btn-bg: var(--btn-bg-gr) var(--btn-fill-brand);
  }
  
  .pr-up-btn{
    background: white;
    color: rgb(48 , 48 , 48);
    box-shadow: var(--btn-box-shadow-white);
  
    border-radius: .5rem;
    padding: .34rem .75rem;
  }
  .pr-up-btn:active{
    background: rgb(247 , 247 , 247);
    box-shadow: var(--btn-box-shadow-active);
  }
  .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #040404;
  }
  
  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #040404;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .nav-for-slider .swiper-slide {
    height: auto;
    width: auto;
    cursor: pointer;
   
}
.swiper-wrapper{
    height: auto;
}
.nav-for-slider .swiper-slide img{
    border:2px solid transparent;
    border-radius: 10px;
   
}
.nav-for-slider .swiper-slide-thumb-active img{
   
    border-color: rgb(79 70 229 );
}